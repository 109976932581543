"use strict";

export function initInScope($scope) {

    $scope.find('.js-flyin__trigger').on('click', function (e) {
        let $target = $($(this).data('flyin-target-id'));

        $target.addClass('is-open');

    });

    $scope.find('.js-flyin__close').on('click', function (e) {

        $('.js-flyin').removeClass('is-open');

    });

}