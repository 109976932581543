"use strict";

import {onEnterViewPort} from "@elements/viewport-utils";

export function initInScope($scope) {
    let $inViewContainer = $scope.find('.js-in-view');

    onEnterViewPort($inViewContainer, function () {
        $(this).addClass('is-in-viewport');
    }, {offset: 500});

}