"use strict";

export function initInScope ($scope) {

    let $infoscreen = $scope.find('.js-infoscreen'),
        refreshMinutes = $infoscreen.data('infoscreen-refresh-minutes'),
        refreshMilliSeconds = refreshMinutes*60*1000;
    setTimeout(function(){
        window.location.reload(1);
    }, refreshMilliSeconds);
}