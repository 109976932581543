"use strict";

import {add} from '@elements/scroll-animations';

export function initInScope($scope) {
    add(
        $scope.find('.js-animation-move-in'),
        calculateAnimationProgress,
        setAnimationProgress
    );
}
function calculateAnimationProgress(element) {
    const start = window.innerHeight / 8 * 7;
    const end = window.innerHeight / 2;

    return {
        transform: matchMedia('(max-width: 767px)').matches ?
            Math.max(Math.min((element.getBoundingClientRect().top - end) / (start - end),100),0) * 30 + 'px'
            : Math.max(Math.min((element.getBoundingClientRect().top - end) / (start - end),100),0) * 80 + 'px'
    };
}

function setAnimationProgress(element, progress) {
    switch ($(element).data('animation-start')) {
        case 'top':
            element.style.transform = 'translate3d(0, -'+ progress.transform +', 0)';
            break;
        case 'left':
            element.style.transform = 'translate3d(-'+ progress.transform +', 0, 0)';
            break;
        case 'right':
            element.style.transform = 'translate3d('+ progress.transform +', 0, 0)';
            break;
        default:
            element.style.transform = 'translate3d(0, '+ progress.transform +', 0)';
    }
}