"use strict";

export function initInScope($scope) {

    try{
        if(!window.sessionStorage.getItem('popupModal')) {
            $scope.find('#popupModal').modal('show');
        }

        $scope.find('#popupModal').on('hidden.bs.modal', function (e) {
            window.sessionStorage.setItem('popupModal', true);
        });

        $scope.find('#popupModal .btn').on('click', function (e) {
            window.sessionStorage.setItem('popupModal', true);
        });

    }catch(e){
        console.log(e);
    }

}