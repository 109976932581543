"use strict";

export function initInScope ($scope) {

    $scope.find('.js-navbar-toggle').on('click', function (e) {
        e.preventDefault();
        let $this = $(this);

        if($this.hasClass('is-open')){
            $this.removeClass('is-open');
            $('body').removeClass('nav-open');

        }else{
            $this.addClass('is-open');
            $('body').addClass('nav-open');
        }
    });

    if(matchMedia('(max-width: 767px)').matches){
        $scope.find('.js-navbar-toggle__subnav').on('click', function (e) {
            e.preventDefault();

            let $thisLi = $(this).closest('li');

            if($thisLi.hasClass('is-open')){
                $thisLi.removeClass('is-open');
            }else{
                $thisLi.addClass('is-open');
            }

        });

    }

}

