import $ from 'jquery';

// import 'popper.js';
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

if (!window.HTMLPictureElement) {
    import('picturefill');
}


let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};
window.app.modules =  app.modules || {};

import initModulesInScope from "@elements/init-modules-in-scope";
app.initModules = function ($scope) {
    console.warn('\'app.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('@elements/load-script').then(function (loadScript) {
        return loadScript.default(url);
    });
};

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

import * as ajaxInclude from './scripts/ajaxInclude';
app.modules.ajaxInclude = ajaxInclude.initInScope;

import * as actionChanger from '@elements/action-changer';
app.modules.actionChanger = actionChanger.initInScope;

import * as goToLink from '@elements/go-to-link';
app.goToLink = goToLink.init;

import * as toc from '@elements/toc';
app.toc = toc.init;
app.modules.toc = toc.initInScope;

import * as lazyImg from '@elements/lazy-img';
app.modules.lazyImg = lazyImg.initInScope;

import * as floatingLabel from '@elements/floating-labels';
app.modules.floatingLabel = floatingLabel.initInScope;

/*
import * as shariff from './scripts/shariff';
app.modules.shariff = shariff.initInScope;
*/

import * as videoJs from './scripts/videoJs';
app.modules.videoJs = videoJs.initInScope;

import * as touchNav from './scripts/touch-nav';
 app.modules.touchNav = touchNav.initInScope;

import * as navAccesibility from '@elements/accessibility-nav';
app.modules.accessibilityNav = navAccesibility.initInScope;

import * as googleEmbedMap from './scripts/google-embed-map';
app.modules.googleEmbedMap = googleEmbedMap.initInScope;

import * as navbarToggle from './scripts/navbar-toggle';
app.modules.navbarToggle = navbarToggle.initInScope;

import * as lightbox from '@elements/lightbox';
app.modules.lightbox = lightbox.createInitInScope({
    base: '.js-lightbox',
    item: '.js-lightbox__item'
});

import * as inView from './scripts/in-view';
app.modules.inView = inView.initInScope;

import * as animationMoveIn from './scripts/animation-move-in';
app.modules.animationMoveIn = animationMoveIn.initInScope;

import * as animationFadeIn from './scripts/animation-fade-in';
app.modules.animationFadeIn = animationFadeIn.initInScope;

import * as matchMediaViewer from "./scripts/matchmedia-viewer";
app.matchMediaViewer = matchMediaViewer.init;
app.modules.matchMediaViewer = matchMediaViewer.initInScope;

import * as parsley from '@elements/parsley-bootstrap-validation';
app.modules.parsley = parsley.initInScope;

import * as iframeResizing from './scripts/iframe-resizing';
app.modules.iframeResizing = iframeResizing.initInScope;

import * as infoscreen from './scripts/infoscreen';
app.modules.infoscreen = infoscreen.initInScope;

import * as flyin from './scripts/flyin';
app.modules.flyin = flyin.initInScope;

import * as popupModal from './scripts/popup';
app.modules.popupModal = popupModal.initInScope;

import * as slider from '@elements/slider';
app.modules.slider = slider.createInitInScope('.js-slider', {
    nextArrow: '<button type="button" class="btn-no-styling slick-next slider__arrow" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-right"></span></button>',
    prevArrow: '<button type="button" class="btn-no-styling slick-prev slider__arrow" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-left"></span></button>',
    infinite: false,
    slidesToShow: 3.4,
    slidesToScroll: 1,
    // variableWidth: true,
    // appendArrows: $('.js-slider').closest('.js-slider-container').find('.js-slider__arrows'),
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1.2
            }
        },
    ]
});

import { showCookieBar } from "@elements/cookie-permissions";
if (!_config.admin && !_config.editmode) {
    showCookieBar();
}

import * as cookieModalHelpers from './scripts/cookieModalHelpers';
app.modules.cookieModalHelpers = cookieModalHelpers.initInScope;

(function( $ ){
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _config, function( _key, _val ){
        if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                window.app[_key]();
            }
        });
    }

    initModulesInScope();
})($);